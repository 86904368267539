export const SET_SCREEN_LOADING = "SET_SCREEN_LOADING";
export const SET_BUTTON_LOADING = "SET_BUTTON_LOADING";
export const SET_NOTIFICATION = "SET_NOTIFICATION";

const initialState = {
  screen_loading: false,
  button_loading: false,
  notification: {
    text: "",
    type: "error",
    show: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCREEN_LOADING:
      return {
        ...state,
        screen_loading: action.payload.loading
      };
    case SET_BUTTON_LOADING:
      return {
        ...state,
        button_loading: action.payload.loading
      };
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: {...action.payload.notification}
      };
    default:
      return state;
  }
};
