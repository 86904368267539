import { keyBy } from "lodash";
import moment from "moment";

export const GET_REPORTS = "GET_REPORTS";
export const CLEAR_REPORTS = "CLEAR_REPORTS";

const initialState = {
  allReports: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return {
        ...state,
        allReports: action.payload
      };
    case CLEAR_REPORTS:
      return {
        ...state,
        allReports: {}
      };
    default:
      return state;
  }
};
