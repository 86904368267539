import { keyBy } from "lodash";
import moment from "moment";

export const GET_PAYMENTS = "GET_PAYMENTS";
export const CLEAR_PAYMENTS = "CLEAR_PAYMENTS";

const initialState = {
  allPayments: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        allPayments: action.payload
      };
    case CLEAR_PAYMENTS:
      return {
        ...state,
        allPayments: []
      };
    default:
      return state;
  }
};
