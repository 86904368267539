import { keyBy } from "lodash";
import moment from "moment";

export const GET_PROMO_CODES = "GET_PROMO_CODES";
export const CLEAR_PROMO_CODES = "CLEAR_PROMO_CODES";
export const CREATE_PROMO_CODES = "CREATE_PROMO_CODES";

const initialState = {
  allPromoCodes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROMO_CODES:
      return {
        ...state,
        allPromoCodes: action.payload
      };
    case CREATE_PROMO_CODES:
      return {
        ...state,
        allPromoCodes: [...state.allPromoCodes, action.payload]
      };
    case CLEAR_PROMO_CODES:
      return {
        ...state,
        allPromoCodes: []
      };
    default:
      return state;
  }
};
