import React from "react";
import store, { history } from "./store";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

const ReduxWrapper = ({ element }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>{element}</ConnectedRouter>
  </Provider>
);

export default ReduxWrapper;
