import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import status from "./status.reducer";
import users from "./users.reducer";
import company from "./company.reducer";
import menu from "./menu.reducer";
import presentation from "./presentations.reducer";
import reports from "./reports.reducer";
import payment from "./payments.reducer";
import promoCode from "./promoCodes.reducer";

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    status,
    users,
    company,
    menu,
    presentation,
    reports,
    payment,
    promoCode
  });
