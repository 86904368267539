import { keyBy } from "lodash";
import moment from "moment";

export const GET_PRESENTATION = "GET_PRESENTATION";
export const GET_PRESENTATIONS = "GET_PRESENTATIONS";
export const CREATE_PRESENTATION = "CREATE_PRESENTATION";
export const CLEAR_PRESENTATIONS = "CLEAR_PRESENTATIONS";
export const REMOVE_PRESENTATION = "REMOVE_PRESENTATION";

const initialState = {
  allPresentations: [],
  presentation: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENTATIONS:
      return {
        ...state,
        allPresentations: action.payload
      };
    case GET_PRESENTATION:
      return {
        ...state,
        presentation: action.payload
      };
    case CREATE_PRESENTATION:
      return {
        ...state,
        allPresentations: [...state.allPresentations, action.payload]
      };
    case REMOVE_PRESENTATION:
      return {
        ...state,
        allPresentations: state.allPresentations.filter((presentation) => presentation.id !== action.payload.id)
      };
    case CLEAR_PRESENTATIONS:
      return {
        ...state,
        allPresentations: []
      };
    default:
      return state;
  }
};
