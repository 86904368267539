import { configureStore } from '@reduxjs/toolkit';

import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createRootReducer } from './reducers';

export const history = createMemoryHistory();

const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const logger = store => next => action => {
    let result = next(action);
    return result;
  };
  middleware.splice(1, 0, logger);
}

const store = configureStore(  {
  reducer: createRootReducer(history),
  middleware
});

export default store;