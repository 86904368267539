import { keyBy } from "lodash";
import moment from "moment";

export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const REMOVE_COMPANY = "REMOVE_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const CLEAR_COMPANIES = "CLEAR_COMPANIES";

const initialState = {
  allCompanies: [],
  company: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        allCompanies: action.payload
      };
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    case CREATE_COMPANY:
      return {
        ...state,
        allCompanies: [...state.allCompanies, action.payload]
      };
    case REMOVE_COMPANY:
      return {
        ...state,
        allCompanies: state.allCompanies.filter((company) => company.id !== action.payload.id)
      };
    case CLEAR_COMPANIES:
      return {
        ...state,
        allCompanies: []
      };
    default:
      return state;
  }
};
